import React from "react";
import homePage from "../svg/homepage.png";

export default () => (

<div className="text-center">
  <div className="flex items-center justify-center pt-10">
    <img className="lg:w-1/2" src={homePage} alt="homepage" />
  </div>
  <br />
  <h1 className="pt-4 text-center text-3xl lg:text-5xl font-semibold">Thank you</h1>
  <br />
  <h3 className="text-1xl font-semibold leading-tight">We have received your
    message. We will get back to you as soon as possible</h3>
  <br />

  <a id="back-link" href="/">
    <button class="bg-transparent hover:bg-blue-500
      text-blue-700 font-semibold hover:text-white py-2 px-4 border
      border-blue-500 hover:border-transparent rounded">
      Go back to our site
    </button>
  </a>



</div>

);
